const dropdownBtn = document.querySelector('.nav-link.dropdown-toggle');
const dropdownMenu = document.querySelector('.dropdown-menu');
const collapseNavbar = document.querySelector('.collapse');
const togglerIcon = document.querySelector('.navbar-toggler-icon');
const blackBanner = document.querySelector('.black-banner');
const accountBtn = document.querySelector('.account');
const accountPopap = document.querySelector('.account-popap');
const logedAccountPopap = document.querySelector('.loged-account-popap');
const accountNavBtn = document.querySelector('.account-nav-btn');
const accountNav = document.querySelector('.account-nav');
const shoppingNav = document.querySelector('.shopping-card');
const shoppingNavBtn = document.querySelector('.shopping-nav-btn');
const paymentNav = document.querySelector('.payment-nav');
const paymentNavBtn = document.querySelector('.payment-nav-btn');
const lackPopap = document.querySelector('.lack-of-product');
const blur1 = document.querySelector('.blur');
const blur2 = document.querySelector('.blur');
const closeUpPopap = document.querySelector('.close-up');
const deliverBtn = document.querySelector('.delivery-btn');
const paymentBtn = document.querySelector('.payment-btn');
// const navbarNav = document.querySelector('.navbar-first-col');
// const navbarIcons = document.querySelector('.navbar-icons');

window.addEventListener('resize', () => {
    if(document.body.clientWidth > 991) {
        dropdownMenu.classList.remove('active');
        collapseNavbar.classList.remove('mobile-active');
        collapseNavbar.classList.remove('mobile-active');
        togglerIcon.classList.remove('rotate');
        dropdownBtn.classList.remove('rotate');
    } 
})

const buttonsListener = () => {
    const photoArrowBtn = document.querySelector('.photo-arrow');
    const newCollectionBtn = document.querySelector('.new-collection-btn');
    const cartBtn = document.querySelector('.cart');
    const searchBnt = document.querySelector('.search');
    const navbarTogglerBtn = document.querySelector('.navbar-toggler');
    const countBtnArray = [...document.querySelectorAll('.count-btn')];
    const shoppingNavBtn = document.querySelector('.shopping-nav-btn');
    const removeBtnsArray = [...document.querySelectorAll('.remove-btn')];
    const closeProductPopapBtn = document.querySelector('.close-pp');
    const closeProductPopap2Btn = document.querySelector('.close-pp2');
    const closeNewsletterPopapBtn = document.querySelector('.close-pp3');
    const closeNewsletter4PopapBtn = document.querySelector('.close-pp4');
    const closeSizePopupBtn = document.querySelector('.close-ps');
    const productPopap = document.querySelector('.product-popap');
    const newsletterPopap = document.querySelector('.newsletter-popap');
    const newsletter2Popap = document.querySelector('.newsletter2-popap');
    const tablePopup = document.querySelector('.table-popup');
    const hideOrderBtn = document.querySelector('.orders-hide');
    const closeLackPopap = document.querySelector('.close-lack-popap')
    const openLackPopapBtn = document.querySelector('.no-items');
    const returnsInstructionBtn = document.querySelector('.returns-instruction-btn');
    const returnsInstructionContent = document.querySelector('.instruction-content');
    const addToCartBtn = document.querySelector('.to-cart');
    const addToNewsletterBtn = document.querySelector('.submit-form');
    const addToNewsletter2Btn = document.querySelector('.submit-newsletter');
    const emailFIeld = document.querySelector('.newsletter2-popap .wpcf7-email');
    const sizeLink = document.querySelector('.size-link');
    const moreOrderBtn = document.querySelector('.see-more');
    const finishReturnBtn = document.querySelector('.finish-return-btn');
    const deliverPopup = document.querySelector('.delivery-popup');
    const paymentPopup = document.querySelector('.payment-popup');

    paymentBtn.addEventListener('click', () => {
        paymentPopup.classList.add('active');
        blur1.classList.add('active');
        document.querySelector('.payment-close-btn').addEventListener('click', () => {
            paymentPopup.classList.remove('active');
            blur1.classList.remove('active');
        })
    })
    deliverBtn.addEventListener('click', () => {
        deliverPopup.classList.add('active');
        blur1.classList.add('active');
        document.querySelector('.close-btn').addEventListener('click', () => {
            deliverPopup.classList.remove('active');
            blur1.classList.remove('active');
        })
    })
    blur2.addEventListener('click', () => {
        if(deliverPopup){
            deliverPopup.classList.remove('active');
        }
        if(paymentPopup){
            paymentPopup.classList.remove('active');
        }
    
        blur2.classList.remove('active');        
    
    })
    if(finishReturnBtn) {
        const infoContent = document.querySelector('.confirm-returned-info');
        finishReturnBtn.addEventListener('click', () => {
            console.log('click');
            infoContent.classList.add('active');
            blur1.classList.add('active');
        })
    }
    if(moreOrderBtn) {
        moreOrderBtn.addEventListener('click', () => {
            console.log('click');
        })
    }
    if(addToCartBtn) {
        const goToCart = document.querySelector('.go-to-cart');
        const continueShopping = document.querySelector('.continue-shopping');
        addToCartBtn.addEventListener('click', (e) => {
            //e.preventDefault();
            blur1.classList.add('active');
            productPopap.classList.add('active');
        })
    }
    if(addToNewsletterBtn) {
        addToNewsletterBtn.addEventListener('click', (e) => {
            blur1.classList.add('active');
            newsletterPopap.classList.add('active');
        })
    }
    if(addToNewsletter2Btn) {
        addToNewsletter2Btn.addEventListener('click', (e) => {
            if(emailFIeld.value.length != 0){
            newsletter2Popap.classList.remove('active');
            blur1.classList.add('active');
            newsletterPopap.classList.add('active');
            }
        })
    }
    if(sizeLink) {    
        sizeLink.addEventListener('click', (e) => {
            e.preventDefault();
            blur1.classList.add('active');
            tablePopup.classList.add('active');
        })
    }
    if(returnsInstructionBtn) {
        const closeInstructionBtn = document.querySelector('.close-instruction');
        returnsInstructionBtn.addEventListener('click', () => {
            blur1.classList.add('active');
            returnsInstructionContent.classList.add('active');
        })
        closeInstructionBtn.addEventListener('click', () => {
            blur1.classList.remove('active');
            returnsInstructionContent.classList.remove('active');
        })
    }
    if(openLackPopapBtn) {
        openLackPopapBtn.addEventListener('click', (e) => {
            e.preventDefault();
            lackPopap.classList.add('active');
            blur1.classList.add('active');
            
        })
    }
    if(closeLackPopap) {
        closeLackPopap.addEventListener('click', (e) => {
            e.preventDefault();
            blur1.classList.remove('active');
            lackPopap.classList.remove('active');
        })
    }
    const hideNavPopap = () => {
        accountPopap.classList.remove('mobile-active');
        logedAccountPopap.classList.remove('mobile-active');
        collapseNavbar.classList.remove('mobile-active');
        togglerIcon.classList.remove('rotate');
    }
    if(hideOrderBtn) {
        const orders = document.querySelector('.orders');
        const hideImg = hideOrderBtn.childNodes[1];
        hideOrderBtn.addEventListener('click', () => {
            orders.classList.toggle('active');
            hideImg.classList.toggle('rotate');
        })
    }
    if(closeProductPopapBtn) {
        closeProductPopapBtn.addEventListener('click', () => {
            productPopap.classList.remove('active');
            closeUpPopap.classList.remove('active');
            blur1.classList.remove('active');
        })
    }
    if(closeNewsletterPopapBtn) {
        closeNewsletterPopapBtn.addEventListener('click', () => {
            newsletterPopap.classList.remove('active');
            blur1.classList.remove('active');
        })
    }
    if(closeNewsletter4PopapBtn) {
        closeNewsletter4PopapBtn.addEventListener('click', () => {
            newsletter2Popap.classList.remove('active');
            blur1.classList.remove('active');
        })
    }
    if(closeProductPopap2Btn) {
        closeProductPopap2Btn.addEventListener('click', () => {
            productPopap.classList.remove('active');
            closeUpPopap.classList.remove('active');
            blur1.classList.remove('active');
        })
    }
    if(closeSizePopupBtn) {
        closeSizePopupBtn.addEventListener('click', () => {
            tablePopup.classList.remove('active');
            blur1.classList.remove('active');
        })
    }
    if(removeBtnsArray.length != 0) {
        removeBtnsArray.forEach((removeBtn, btnId) => {
            removeBtn.addEventListener('click', () => {
                console.log('click id: ' + btnId);
            })
        })
    }
    if(paymentNavBtn) {
        paymentNavBtn.addEventListener('click', () => {
            paymentNavBtn.classList.toggle('rotate');
            paymentNav.classList.toggle('active');
            hideNavPopap();
        })
    }
    if(accountNavBtn) {
        accountNavBtn.addEventListener('click', () => {
            accountNav.classList.toggle('show');
            accountNavBtn.classList.toggle('rotate');
            hideNavPopap();
        })
    }
    if(shoppingNavBtn) {
        shoppingNavBtn.addEventListener('click', () => {
            shoppingNav.classList.toggle('active');
            shoppingNavBtn.classList.toggle('rotate');
            hideNavPopap();
        })
    }
    if(photoArrowBtn) {
        photoArrowBtn.addEventListener('click', () => {
            window.scrollTo({
                top: blackBanner.offsetTop - 84,
                behavior: 'smooth'
            });
        })
    }
    dropdownBtn.addEventListener('click' , () => {
        dropdownMenu.classList.toggle('active');
        dropdownBtn.classList.toggle('rotate');
    })
    navbarTogglerBtn.addEventListener('click', () => {
        collapseNavbar.classList.toggle('mobile-active');
        togglerIcon.classList.toggle('rotate');
        accountPopap.classList.remove('active');
        dropdownMenu.classList.remove('active');
        accountPopap.classList.remove('mobile-active');
        logedAccountPopap.classList.remove('active');
        logedAccountPopap.classList.remove('mobile-active');
        
        if(paymentNav != null) {
            paymentNav.classList.remove('active');
            paymentNavBtn.classList.remove('rotate');
        }
        if(accountNav != null) {
            accountNav.classList.remove('show');
            accountNavBtn.classList.remove('rotate');
        }
        if(shoppingNav != null) {
            shoppingNav.classList.remove('active');
            shoppingNavBtn.classList.remove('rotate');
        }
    })
    if(newCollectionBtn) {
        newCollectionBtn.addEventListener('click', () => {
            console.log('click');
        })
    }
    if(countBtnArray) {
        countBtnArray.forEach(btn => {
            btn.addEventListener('click', () => {
                console.log(btn);
                btn.classList.toggle('active');
                btn.childNodes[3].classList.toggle('active');
            })
        })
    }


    searchBnt.addEventListener('click', (e) => {
        console.log('click');
    })
    cartBtn.addEventListener('click', () => {
        console.log('click');
    })
}

const sliderHandler = () => {
    const leftArrowBtn = document.querySelector('.stylist-left-arrow');
    const rightArrowBtn = document.querySelector('.stylist-right-arrow');
    const slidesArray = document.querySelectorAll('.slide');
    let regularSlideChange;
    let didSlideEnd = true;
    let next; 
    let prev;
    let current;
    let currentId;

    if(leftArrowBtn != null) {
        leftArrowBtn.addEventListener('click', () => {
            if(didSlideEnd) {
                didSlideEnd = false;
                currentCheck();
                next.classList.remove('next');
                prev.classList.add('current');
                prev.classList.remove('prev');
                next.classList.add('prev');
                next.classList.add('hide');
                current.classList.add('next');
                current.classList.remove('current');
                setTimeout (() => removeHideClass(), 1000);
                setTimeout(() => {didSlideEnd = true}, 1000);
            }
        })
    }
    if(rightArrowBtn != null) {
        rightArrowBtn.addEventListener('click', () => {
            if(didSlideEnd) {
                didSlideEnd = false
                currentCheck();
                changeSlideOnNext();
                setTimeout(() => {didSlideEnd = true}, 1000);
            }
        })
    }
    const currentCheck = () => {
        current = document.querySelector('.slide.current');
        currentId = parseInt(current.dataset.tag);
        if(currentId == 0) {
            madeNextPrev(currentId + 1, slidesArray.length - 1);
        } else if(currentId == slidesArray.length - 1) {
            madeNextPrev(0,  currentId - 1);
        } else {
            madeNextPrev(currentId + 1, currentId - 1);
        }
        removeHideClass();
    }
    const madeNextPrev = (idNext, idPrev) => {
        for(const slide of slidesArray) {
            if(slide.dataset.tag == idNext) {
                next = slide;
                next.classList.add('next')
            } else if(slide.dataset.tag == idPrev) {
                prev = slide;
                prev.classList.add('prev');
            }
        }
    }

    const changeSlideOnNext = () => {
        didSlideEnd = false;
        currentCheck();
        prev.classList.add('next');
        prev.classList.add('hide');
        prev.classList.remove('prev');
        next.classList.add('current');
        next.classList.remove('next');
        current.classList.add('prev');
        current.classList.remove('current');
        setTimeout(() => {didSlideEnd = true}, 1000);
        setTimeout (() => removeHideClass(), 1000);
    }
    const removeHideClass = () => {
        for(const slide of slidesArray) {
            slide.classList.remove('hide');
        }
    }
    if(leftArrowBtn != null & rightArrowBtn != null) {
        currentCheck();
    }
}
const loginFunction = () => {
    const passwordUnlockBtn = document.querySelector('.password-visible');
    const passwordLockBtn = document.querySelector('.password-unvisible');
    const loginBtn = document.querySelector('#login-form');
    const password = document.querySelector('#password');

    passwordUnlockBtn.addEventListener('click', () => {
        password.type = "text";
        passwordLockBtn.classList.remove('hide');
        passwordUnlockBtn.classList.add('hide');
    })
    passwordLockBtn.addEventListener('click', () => {
        password.type = "password";
        passwordUnlockBtn.classList.remove('hide');
        passwordLockBtn.classList.add('hide');
    })


    loginBtn.addEventListener('submit', (e) => {
        e.preventDefault();
        const email = document.querySelector('#email').value;
        const password = document.querySelector('#password').value;
        const login = {
            email,
            password,
        }
        console.log(login);
    })
}
const registerFunction = () => {
    const passwordUnlockBtn = document.querySelector('.password-visible');
    const passwordLockBtn = document.querySelector('.password-unvisible');
    const registerBtn = document.querySelector('#register-form');
    const password = document.querySelector('#password');

    passwordUnlockBtn.addEventListener('click', () => {
        password.type = "text";
        passwordLockBtn.classList.remove('hide');
        passwordUnlockBtn.classList.add('hide');
    })
    passwordLockBtn.addEventListener('click', () => {
        password.type = "password";
        passwordUnlockBtn.classList.remove('hide');
        passwordLockBtn.classList.add('hide');
    })

    registerBtn.addEventListener('submit', (e) => {
        e.preventDefault();
        const email = document.querySelector('#email').value;
        const name = document.querySelector('#name').value;
        const surname = document.querySelector('#surname').value;
        const password = document.querySelector('#password').value;
        const newsletterSign = document.querySelector('#newsletter-permission').checked;
        const register = {
            email,
            name,
            surname,
            password,
            newsletterSign,
        }
        console.log(register);
    })
}
const changeData = () => {
    const companyInput = document.querySelector('#company');
    const customerInput = document.querySelector('#individual-customer');
    const companyName = document.querySelector('#company-name');
    const nip = document.querySelector('#nip');
    const streetInvoice = document.querySelector('#street-invoice');
    const homeNumber = document.querySelector('#home-number-invoice');
    const cityInvoice = document.querySelector('#city-invoice');
    const zipCode = document.querySelector('#zip-code-invoice');
    const radioAddres = [...document.querySelectorAll("input[name=deliv-addres]")];

    const invoiceDropdown = document.querySelector('.invoice-dropdown');
    const currentAdresInput = document.querySelector('#current-adres');
    const newAdresInput = document.querySelector('#new-adres');
    const invoiceForm = document.querySelector('.invoice');

    const newName = document.querySelector('#new-name');
    const newSurname = document.querySelector('#new-surname');
    const newTelephone = document.querySelector('#new-number');
    const newStreet = document.querySelector('#new-street');
    const newHomeNumber = document.querySelector('#new-home-number');
    const newCity = document.querySelector('#new-city');
    const newZipCode = document.querySelector('#new-zip-code');


    currentAdresInput.addEventListener('click', () => {
        invoiceForm.classList.add('invisible');
        newName.removeAttribute('required', true);
        newSurname.removeAttribute('required', true);
        newTelephone.removeAttribute('required', true);
        newStreet.removeAttribute('required', true);
        newHomeNumber.removeAttribute('required', true);
        newCity.removeAttribute('required', true);
        newZipCode.removeAttribute('required', true);
        companyName.removeAttribute('required', true);
        nip.removeAttribute('required', true);
        streetInvoice.removeAttribute('required', true);
        homeNumber.removeAttribute('required', true);
        cityInvoice.removeAttribute('required', true);
        zipCode.removeAttribute('required', true);
        customerInput.removeAttribute('required', true);
    })
    newAdresInput.addEventListener('click', () => {
        invoiceForm.classList.remove('invisible');
        newName.setAttribute('required', true);
        newSurname.setAttribute('required', true);
        newTelephone.setAttribute('required', true);
        newStreet.setAttribute('required', true);
        newHomeNumber.setAttribute('required', true);
        newCity.setAttribute('required', true);
        newZipCode.setAttribute('required', true);
        customerInput.setAttribute('required', true);
    })
    customerInput.addEventListener('click', () => {
        invoiceDropdown.classList.remove('visible');
        invoiceDropdown.classList.add('invisible');
        companyName.removeAttribute('required', true);
        nip.removeAttribute('required', true);
        streetInvoice.removeAttribute('required', true);
        homeNumber.removeAttribute('required', true);
        cityInvoice.removeAttribute('required', true);
        zipCode.removeAttribute('required', true);
    })
    companyInput.addEventListener('click', () => {
        invoiceDropdown.classList.add('visible');
        invoiceDropdown.classList.remove('invisible');
        companyName.setAttribute('required', true);
        nip.setAttribute('required', true);
        streetInvoice.setAttribute('required', true);
        homeNumber.setAttribute('required', true);
        cityInvoice.setAttribute('required', true);
        zipCode.setAttribute('required', true);
    })

    const changeDataBtn = document.querySelector('#data-form');
    const data = {};
    changeDataBtn.addEventListener("submit", (e) => {
        e.preventDefault();
        data.gender = document.querySelector('input[name="gender"]:checked').id;
        data.name = document.querySelector('#name').value;
        data.surname = document.querySelector('#surname').value;
        data.telephoneNumber = document.querySelector('#current-number').value;
        data.email = document.querySelector('#data-email').value;

        data.password = document.querySelector('#password').value;
        data.newPassword = document.querySelector('#new-password').value;
        data.confirmPassword = document.querySelector('#confirm-password').value;
        data.permission = document.querySelector('#permission').checked;

        radioAddres.forEach(radio => {
            if(radio.checked) {
                data.addres = radio.value;
            }
        })
        if(companyName.value) {
            data.companyName = companyName.value;
            data.nip = nip.value;
            data.streetInvoice = streetInvoice.value;
            data.homeNumberInvoice = homeNumber.value;
            data.cityInvoice = cityInvoice.value;
            data.zipCodeInvoice = zipCode.value;
        }
        if(newAdresInput.checked) {
            data.newName = newName.value;
            data.newSurname = newSurname.value;
            data.newTelephoneNumber = newTelephone.value;
            data.newStreet = newStreet.value;
            data.newHomeNumber = newHomeNumber.value;
            data.newCity = newCity.value;
            data.newZipCode = newZipCode.value;
        }
        console.log(data);
    })
}
const shopHover = () => {
    const shopBtn = document.querySelector('.shop-btn');
    const shopDropdown = document.querySelector('.shop-menu');

    if(document.body.clientWidth > 991) {
        shopBtn.addEventListener('mouseover', () => {
            shopDropdown.classList.add('active');
        })
        shopDropdown.addEventListener('mouseover', () => {
            shopDropdown.classList.add('active');
        })
        shopDropdown.addEventListener('mouseleave', () => {
            setTimeout(() => {
                shopDropdown.classList.remove('active');
            }, 500)
        })
    }
}
const accountFunction = () => {
    let didSomeoneIsLoged = $('body').hasClass('logged-in');

    if(didSomeoneIsLoged == true) {
        accountPopap.classList.add('loged');
        logedAccountPopap.classList.remove('logout')
    } else {
        accountPopap.classList.remove('loged');
        logedAccountPopap.classList.add('logout')
    }

    const accBttListener = () => {
        if(document.body.clientWidth > 991) {
            accountPopap.classList.remove('active');
            accountPopap.classList.remove('mobile-active');
            logedAccountPopap.classList.remove('active');
            logedAccountPopap.classList.remove('mobile-active');

            accountBtn.removeEventListener('click', () => {
                accountPopap.classList.toggle('mobile-active');
                logedAccountPopap.classList.toggle('mobile-active');
                collapseNavbar.classList.remove('mobile-active');
                togglerIcon.classList.remove('rotate');
            })
            accountPopap.addEventListener('mouseleave', () => {
                setTimeout(() => {
                    accountPopap.classList.remove('active');
                }, 500)
            })
            logedAccountPopap.addEventListener('mouseleave', () => {
                setTimeout(() => {
                    logedAccountPopap.classList.remove('active');
                }, 500)
            })
            accountBtn.addEventListener('mouseover', () => {
                accountPopap.classList.add('active');
                logedAccountPopap.classList.add('active');
            })
            accountPopap.addEventListener('mouseover', () => {
                accountPopap.classList.add('active');
            })
            logedAccountPopap.addEventListener('mouseover', () => {
                logedAccountPopap.classList.add('active');
            })
        } else if (document.body.clientWidth <= 991) {
            {
                accountPopap.removeEventListener('mouseleave', () => {
                    accountPopap.classList.remove('active');
                })
                logedAccountPopap.removeEventListener('mouseleave', () => {
                    logedAccountPopap.classList.remove('active');
                })
                accountBtn.removeEventListener('mouseleave', () => {
                    logedAccountPopap.classList.remove('active');
                    accountPopap.classList.remove('active');
                })
                accountPopap.removeEventListener('mouseover', () => {
                    accountPopap.classList.add('active');
                })
                logedAccountPopap.removeEventListener('mouseover', () => {
                    logedAccountPopap.classList.add('active');
                })
    
                accountBtn.addEventListener('click', () => {
                    accountPopap.classList.toggle('mobile-active');
                    logedAccountPopap.classList.toggle('mobile-active');
                    dropdownMenu.classList.remove('active');
                    collapseNavbar.classList.remove('mobile-active');
                    togglerIcon.classList.remove('rotate');
                    if(paymentNav != null) {
                        paymentNav.classList.remove('active');
                        paymentNavBtn.classList.remove('rotate');
                    }
                    if(accountNav != null) {
                        accountNav.classList.remove('show');
                        accountNavBtn.classList.remove('rotate');
                    }
                    if(shoppingNav != null) {
                        shoppingNav.classList.remove('active');
                        shoppingNavBtn.classList.remove('rotate');
                    }
                })
            }
        }
    }

    window.addEventListener('resize', () => {
        accBttListener();
    })

    accBttListener();
}
const sendMessage = () => {
    const messageBtn = document.querySelector('#message-form');
    messageBtn.addEventListener('submit', (e) => {
        e.preventDefault();
        const name = document.querySelector('#message-name').value;
        const surname = document.querySelector('#message-surname').value;
        const email = document.querySelector('#message-email').value;
        const subject = document.querySelector('#message-subject').value;
        const contents = document.querySelector('#message').value;
        const permission = document.querySelector('#permission-check').checked;

        const message = {
            name,
            surname,
            email,
            subject,
            contents,
            permission
        }
        console.log(message);
    })
}
const deliveryFunction = () => {
    const deliverBtn = document.querySelector('#delivery-form');
    const cardNumberCheckbox = document.querySelector('#card-number');
    const cardDateCheckbox = document.querySelector('#card-date');
    const cardCvvCheckbox = document.querySelector('#card-cvv');
    const masterCard = document.querySelector('#master-card');
    const blik = document.querySelector('#blik');
    const payu = document.querySelector('#payu');
    const radioDeliverArray = [...document.querySelectorAll("input[name=delivery]")];
    const radioPaymentArray = [...document.querySelectorAll("input[name=payment]")];
    const radioAddres = [...document.querySelectorAll("input[name=deliv-addres]")];
    const buyConfirmPopap = document.querySelector('.confirm-buy-info');
    const companyInput = document.querySelector('#company');

    const customerInput = document.querySelector('#individual-customer');
    const companyName = document.querySelector('#company-name');
    const nip = document.querySelector('#nip');
    const streetInvoice = document.querySelector('#street-invoice');
    const homeNumber = document.querySelector('#home-number-invoice');
    const cityInvoice = document.querySelector('#city-invoice');
    const zipCode = document.querySelector('#zip-code-invoice');

    const invoiceDropdown = document.querySelector('.invoice-dropdown');
    const currentAdresInput = document.querySelector('#current-adres');
    const newAdresInput = document.querySelector('#new-adres');
    const invoiceForm = document.querySelector('.invoice');

    const newName = document.querySelector('#new-name');
    const newSurname = document.querySelector('#new-surname');
    const newTelephone = document.querySelector('#new-number');
    const newStreet = document.querySelector('#new-street');
    const newHomeNumber = document.querySelector('#new-home-number');
    const newCity = document.querySelector('#new-city');
    const newZipCode = document.querySelector('#new-zip-code');

    currentAdresInput.addEventListener('click', () => {
        invoiceForm.classList.add('invisible');
        newName.removeAttribute('required', true);
        newSurname.removeAttribute('required', true);
        newTelephone.removeAttribute('required', true);
        newStreet.removeAttribute('required', true);
        newHomeNumber.removeAttribute('required', true);
        newCity.removeAttribute('required', true);
        newZipCode.removeAttribute('required', true);
        companyName.removeAttribute('required', true);
        nip.removeAttribute('required', true);
        streetInvoice.removeAttribute('required', true);
        homeNumber.removeAttribute('required', true);
        cityInvoice.removeAttribute('required', true);
        zipCode.removeAttribute('required', true);
        customerInput.removeAttribute('required', true);
    })
    newAdresInput.addEventListener('click', () => {
        invoiceForm.classList.remove('invisible');
        newName.setAttribute('required', true);
        newSurname.setAttribute('required', true);
        newTelephone.setAttribute('required', true);
        newStreet.setAttribute('required', true);
        newHomeNumber.setAttribute('required', true);
        newCity.setAttribute('required', true);
        newZipCode.setAttribute('required', true);
        customerInput.setAttribute('required', true);
    })
    customerInput.addEventListener('click', () => {
        invoiceDropdown.classList.remove('visible');
        invoiceDropdown.classList.add('invisible');
        companyName.removeAttribute('required', true);
        nip.removeAttribute('required', true);
        streetInvoice.removeAttribute('required', true);
        homeNumber.removeAttribute('required', true);
        cityInvoice.removeAttribute('required', true);
        zipCode.removeAttribute('required', true);
    })
    companyInput.addEventListener('click', () => {
        invoiceDropdown.classList.add('visible');
        invoiceDropdown.classList.remove('invisible');
        companyName.setAttribute('required', true);
        nip.setAttribute('required', true);
        streetInvoice.setAttribute('required', true);
        homeNumber.setAttribute('required', true);
        cityInvoice.setAttribute('required', true);
        zipCode.setAttribute('required', true);
    })

    masterCard.addEventListener('click', () => {
        cardCvvCheckbox.setAttribute('required', true);
        cardDateCheckbox.setAttribute('required', true);
        cardNumberCheckbox.setAttribute('required', true);
    })
    payu.addEventListener('click', () => {
        removeRequired();
    })
    blik.addEventListener('click', () => {
        removeRequired();
    })
    const removeRequired = () => {
        cardCvvCheckbox.removeAttribute('required', true);
        cardDateCheckbox.removeAttribute('required', true);
        cardNumberCheckbox.removeAttribute('required', true);
    }
    deliverBtn.addEventListener('submit', (e) => {
        e.preventDefault();
        const orderInfo = {}
        
        radioDeliverArray.forEach(radio => {
            if(radio.checked) {
                orderInfo.deliveryType = radio.value;
            }
        });
        radioPaymentArray.forEach(radio => {
            if(radio.checked) {
                orderInfo.deliveryPayment = radio.value;
                if(radio.value == 'master-card') {
                    orderInfo.cardNumber = document.querySelector('#card-number').value,
                    orderInfo.cardDate = document.querySelector('#card-date').value,
                    orderInfo.cadCvv = document.querySelector('#card-cvv').value
                }
            } 
        })
        if(companyName.value) {
            orderInfo.companyName = companyName.value;
            orderInfo.nip = nip.value;
            orderInfo.streetInvoice = streetInvoice.value;
            orderInfo.homeNumberInvoice = homeNumber.value;
            orderInfo.cityInvoice = cityInvoice.value;
            orderInfo.zipCodeInvoice = zipCode.value;
        }
        if(newAdresInput.checked) {
            orderInfo.client = 'Klient infywidualny';
            orderInfo.newName = newName.value;
            orderInfo.newSurname = newSurname.value;
            orderInfo.newTelephoneNumber = newTelephone.value;
            orderInfo.newStreet = newStreet.value;
            orderInfo.newHomeNumber = newHomeNumber.value;
            orderInfo.newCity = newCity.value;
            orderInfo.newZipCode = newZipCode.value;
        }
        
        console.log(orderInfo);
        buyConfirmPopap.classList.add('active');
        blur1.classList.add('active');
    })
}
const notifyFunction = () => {
    const notifyBtn = document.querySelector('#notify');
    if(notifyBtn) {
        notifyBtn.addEventListener('submit', (e) => {
            e.preventDefault();
            email = document.querySelector('#notify-email').value;
            blur1.classList.remove('active');
            setTimeout(() => {
                lackPopap.classList.remove('active');
                console.log(email);
            }, 500)
        })
    }
}

let current = null;
const galeryArray = [...document.querySelectorAll("div.box-img[data-id]")];

const openModal = (id) => {
    closeUpPopap.classList.add('active');
    blur1.classList.add('active');
    current = id;

    galeryArray.forEach(box => {
        if(box.getAttribute('data-id') == id) {
            box.classList.add('show');
        } else {
            box.classList.remove('show');
        }
    })

    blur1.addEventListener('click', () => {
        closeUpPopap.classList.remove('active');

        blur1.classList.remove('active');        

        blur1.removeEventListener('click', () => {
            blur1.classList.remove('active');
        });
    })
}

const nextImg = () => {
    current +=1;
    if(current >= galeryArray.length) {
        current = 0;
    }
    galeryArray.forEach(box => {
        if(box.getAttribute('data-id') == current) {
            box.classList.add('show');
        } else {
            box.classList.remove('show');
        }
    })
}
const prevImg = () => {
    current -=1;
    if(current < 0) {
        current = galeryArray.length-1;
    }
    galeryArray.forEach(box => {
        if(box.getAttribute('data-id') == current) {
            box.classList.add('show');
        } else {
            box.classList.remove('show');
        }
    })
}

const returnChoiceFunction = () => {
    const returnsChoicesBtn = document.querySelector('.return-choices-btn');
    const selectAllBtn = document.querySelector('.select-all-btn');
    const unselectAllBtn = document.querySelector('.unselect-all-btn');
    const unselectAllBtnSecond = document.querySelector('.unselect-all-second-btn');
    const confirmReturnBtn = document.querySelector('.confirm-return-btn');
    const selectBtnArray = [...document.querySelectorAll('.select-btn')];
    const inputToReturnArray = [...document.querySelectorAll("input[data-name=to-return]")];
    const arrayOfPrices = [...document.querySelectorAll('.price')];
    const totalPriceAktualization = () => {
        let totalPrice = 0;
        let ordersCount = 0;
        inputToReturnArray.forEach((input, inputId) => {
            if(input.checked) {
                ordersCount +=1;
                arrayOfPrices.forEach((price, priceId) => {
                    if(priceId == inputId) {

                        totalPrice += parseInt(price.getAttribute('value'));
                    }
                })
            }
        })

        if(ordersCount == 0) {
            unselectAllBtn.classList.add('hide');
            unselectAllBtnSecond.classList.add('hide');
            selectAllBtn.classList.remove('hide');
        } else if(ordersCount == inputToReturnArray.length) {
            unselectAllBtn.classList.remove('hide');
            unselectAllBtnSecond.classList.add('hide');
            selectAllBtn.classList.add('hide');
        } else {
            unselectAllBtn.classList.add('hide');
            unselectAllBtnSecond.classList.remove('hide');
            selectAllBtn.classList.add('hide');
        }



        const priceHTML = document.createElement('span');
        priceHTML.classList.add('to-changes');
        priceHTML.innerHTML = `${totalPrice} PLN`;
        const priceContainer = document.querySelector('.total-price');
        const childToRemove = document.querySelector('.to-changes');
        priceContainer.removeChild(childToRemove);
        priceContainer.appendChild(priceHTML);
    }
    selectBtnArray.forEach(button => {
        button.addEventListener('click', () => {
            if(button.getAttribute('src') == './assets/img/check.svg') {
                button.setAttribute('src', "./assets/img/check-empty.svg");
            } else {
                button.setAttribute('src', "./assets/img/check.svg");
            }
            const buttonId = button.getAttribute('data-id');

            inputToReturnArray.forEach(input => {
                const inputId = input.getAttribute('data-id');
                if(inputId == buttonId) {
                    input.toggleAttribute('checked');
                }
            })
            totalPriceAktualization();
        })
    })
    selectAllBtn.addEventListener('click', () => {
        unselectAllBtn.classList.remove('hide');
        unselectAllBtnSecond.classList.add('hide');
        selectAllBtn.classList.add('hide');

        selectBtnArray.forEach(button => {
            button.setAttribute('src', "./assets/img/check.svg");
        })
        inputToReturnArray.forEach(input => {
            input.setAttribute('checked', true);
        })
        totalPriceAktualization();
    })
    unselectAllBtn.addEventListener('click', () => {
        unselectAllBtn.classList.add('hide');
        unselectAllBtnSecond.classList.add('hide');
        selectAllBtn.classList.remove('hide');

        unselectFunction();
    })
    unselectAllBtnSecond.addEventListener('click', () => {
        unselectAllBtn.classList.add('hide');
        unselectAllBtnSecond.classList.add('hide');
        selectAllBtn.classList.remove('hide');

        unselectFunction();
    })
    const unselectFunction = () => {
        selectBtnArray.forEach(button => {
            button.setAttribute('src', "./assets/img/check-empty.svg");
        })
        inputToReturnArray.forEach(input => {
            input.removeAttribute('checked', true);
        })
        totalPriceAktualization();
    }

    returnsChoicesBtn.addEventListener('click', () => {
        console.log('click');
    })
    confirmReturnBtn.addEventListener('click', () => {
        console.log('click');
    })
    
}
jQuery(window).scroll(function() {    
    var scroll = jQuery(window).scrollTop();
    if (scroll >= 200) {
        jQuery("header .container .navbar").addClass("scroll");
        jQuery(".my-data .container .row .account-nav").addClass("scroll");
    } else {
        jQuery("header .container .navbar").removeClass("scroll");
        jQuery(".my-data .container .row .account-nav").removeClass("scroll");
    }
});


const path = document.location.pathname;
if(path.includes('login' )) {
    loginFunction();
    logedAccountPopap.style.display = 'none';
} else if (path.includes('register')) {
    registerFunction();
    logedAccountPopap.style.display = 'none';
} else if (path.includes('my-data')) {
    changeData();

} else if (path.includes('message')) {
    sendMessage();
} else if (path.includes('payment')) {
    deliveryFunction();
} else if (path.includes('product-details')) {
    notifyFunction();
} else if (path.includes('my-return-choices')) {
    returnChoiceFunction();
}
function zoom(e){
    if (window.innerWidth > 900) {
    var zoomer = e.currentTarget;
    e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
    e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX
    x = offsetX/zoomer.offsetWidth*100
    y = offsetY/zoomer.offsetHeight*100
    zoomer.style.backgroundPosition = x + '% ' + y + '%';
    }
  }

accountFunction();
sliderHandler();
buttonsListener();
shopHover();